<template>
  <div
    v-resize="myChart && myChart.resize"
    style="height: 400px; margin-top: 20px; background:#fff"
    ref="lineChart"
  ></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import * as dayjs from "dayjs";
import { unwarp } from "@/utils";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const dataList = () => {
      let date = [];
      let num = [];
      let nowTime = new Date().getTime();
      for (let i = 0; i < 30; i++) {
        date.push(dayjs(nowTime - i * 1000).format("HH:mm:ss"));
        num.push((Math.random() * 10).toFixed(0) * 1);
      }
      return {
        date: date.reverse(),
        num,
      };
    };

    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      LineChart,
      CanvasRenderer,
    ]);
    const lineChart = ref(null);
    const myChart = ref(null);

    let option = {
      title: {
        text: "用户访问量",
      },
      xAxis: {
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yAxis: {
        type: "value",
      },
      grid: {
        left: "0",
        right: "0",
        top: "10%",
        bottom: "0",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
      },
      series: [
        {
          data: [150, 230, 224, 218, 135, 147, 260],
          type: "line",
        },
      ],
    };
    /**
     * @description: 动态数据
     * @param {*}
     * @return {*}
     */
    const getNewData = (myChart) => {
      unwarp(myChart.value).setOption(option);
    };
    onMounted(() => {
      myChart.value = echarts.init(lineChart.value);
      getNewData(myChart);
    });

    return {
      dataList,
      // $_dataList,
      lineChart,
      myChart,
      getNewData,
    };
  },
});
</script>

<style lang="scss" scoped></style>
