<template>
  <div
    v-resize="myChart && myChart.resize"
    style="height: 400px; margin-top: 20px; background: #fff"
    ref="pieChart"
  ></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ToolboxComponent
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import * as dayjs from "dayjs";
import { unwarp } from "@/utils";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const dataList = () => {
      let date = [];
      let num = [];
      let nowTime = new Date().getTime();
      for (let i = 0; i < 30; i++) {
        date.push(dayjs(nowTime - i * 1000).format("HH:mm:ss"));
        num.push((Math.random() * 10).toFixed(0) * 1);
      }
      return {
        date: date.reverse(),
        num,
      };
    };
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      PieChart,
      CanvasRenderer,
      LegendComponent,
      ToolboxComponent
    ]);
    const pieChart = ref(null);
    const myChart = ref(null);

    let option = {
      title: {
        text: "用户访问量",
      },
      legend: {
        bottom:'10px'
      },
      toolbox: {
        show: true,
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      series: [
        {
          name: "Nightingale Chart",
          type: "pie",
          radius: [10, 150],
          center: ["50%", "50%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 8,
          },
          data: [
            { value: 40, name: "rose 1" },
            { value: 38, name: "rose 2" },
            { value: 32, name: "rose 3" },
            { value: 30, name: "rose 4" },
            { value: 28, name: "rose 5" },
            { value: 26, name: "rose 6" },
            { value: 22, name: "rose 7" },
            { value: 18, name: "rose 8" },
          ],
        },
      ],
    };

    /**
     * @description: 动态数据
     * @param {*}
     * @return {*}
     */
    const getNewData = (myChart) => {
      unwarp(myChart.value).setOption(option);
    };
    onMounted(() => {
      myChart.value = echarts.init(pieChart.value);
      getNewData(myChart);
    });

    return {
      dataList,
      // $_dataList,
      pieChart,
      myChart,
      getNewData,
    };
  },
});
</script>

<style lang="scss" scoped></style>
