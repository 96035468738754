<template>
  <div class="gutter-example">
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="4.9">
        <div class="gutter-box">
          <div class="box-item-left">
            <UsergroupAddOutlined class="box-item-icon"/>
          </div>
          <div class="box-item-count">
            <a-statistic
              title="当日总浏览量"
              :value="countmap.srCurriculumUserByDay"
              style="margin-right: 20px"
            />
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="4.9">
        <div class="gutter-box">
         <div class="box-item-left">
            <UsergroupAddOutlined class="box-item-icon"/>
          </div>
          <div class="box-item-count">
            <a-statistic
              title="当日活跃数"
              :value="countmap.SrMoveUserByDayCount"
              style="margin-right: 20px"
            />
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="4.9">
        <div class="gutter-box">
          <div class="box-item-left">
            <UsergroupAddOutlined class="box-item-icon"/>
          </div>
          <div class="box-item-count">
            <a-statistic
              title="所有移动端用户数"
              :value="countmap.srMoveUserCount"
              style="margin-right: 20px"
            />
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="4.9">
        <div class="gutter-box">
        <div class="box-item-left">
            <UsergroupAddOutlined class="box-item-icon"/>
          </div>
          <div class="box-item-count">
            <a-statistic
              title="查询所有课程量"
              :value="countmap.srCurriculumCount"
              style="margin-right: 20px"
            />
          </div>
      </div>
      </a-col>
      <a-col class="gutter-row" :span="4.9">
        <div class="gutter-box">
        <div class="box-item-left">
            <UsergroupAddOutlined class="box-item-icon"/>
          </div>
          <div class="box-item-count">
            <a-statistic
              title="课程总浏览量"
              :value="countmap.srCurriculumByViewsCount"
              style="margin-right: 20px"
            />
          </div>
      </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import { defineComponent,onMounted,ref } from "vue";
import { myGet } from "@/common/request.js";
import { UsergroupAddOutlined } from "@ant-design/icons-vue";

const api = {
  // 查询
  queryPageUser: "/xkcwebApi/srCurriculumUser/selectSrCurriculumUserCount"
};

export default defineComponent({
  components: {
    UsergroupAddOutlined,
  },
    setup() {
    const countmap = ref({})
    const queryTree = () => {
      myGet(api.queryPageUser, { id: 1 }).then(res => {
        if (res.code === 200) {
          countmap.value = res.data.countmap
        }
      });
    };

    onMounted(() => {
      // console.log('123')
      queryTree();
    });
    return {
      queryTree,
      countmap
    }
  }
});
</script>

<style scoped>
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  display: flex;
  justify-content: space-between;
  /* background: #00a0e9; */
  background: #fff;
  padding: 5px 0;
}
.box-item-left {
  margin-left: 20px;
  font-size: 12px;
}
.box-item-left:hover { 
  width: 50px;
  height: 60px;
  background-color:yellow;
}
.box-item-icon {
  color: rgb(64, 201, 198);
  font-size: 50px;
  align-items: center;
  margin-top: 5px;
}
/* .box-item-icon:hover { 
  width: 50px;
  height: 50px;
  background-color:yellow;
} */
</style>