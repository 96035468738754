<!--
 * @Author: your name
 * @Date: 2021-01-18 15:46:34
 * @LastEditTime: 2021-10-15 14:43:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vue3-element-admin\src\views\Home.vue
-->
<template>
  <div class="ve_home">
    <shortcuts />
    <a-row type="flex" :gutter="20">
      <a-col :span="12">
        <live-chart />
      </a-col>
      <a-col :span="12">
        <line-chart />
      </a-col>
      <a-col :span="12">
        <pie-chart />
      </a-col>
      <a-col :span="12">
        <pie-two />
      </a-col>
    </a-row>
  </div>
</template>

<script>
// const api = {
//   // 查询
//   queryPageUser: "/xkcwebApi/srCurriculumUser/selectSrCurriculumUserCount"
// };


import { myGet } from "@/common/request.js";

import { defineComponent, onMounted } from "vue";
import Shortcuts from "@/components/dashboard/Shortcuts";
import LiveChart from "@/components/dashboard/LiveChart";
import LineChart from "@/components/dashboard/LineChart";
import PieChart from "@/components/dashboard/PieChart";
import PieTwo from "@/components/dashboard/PieChartTwo";

export default defineComponent({
  components: { LiveChart, Shortcuts, LineChart, PieChart, PieTwo },
  // setup() {
  //   const queryTree = () => {
  //     myGet(api.queryPageUser, { id: 1 }).then(res => {
  //     });
  //   };

  //   onMounted(() => {
  //     // console.log('123')
  //     // queryTree(1);
  //   });
  //   return {
  //     queryTree
  //   }
  // }
});
</script>

<style lang="scss" scoped>
.ve_calendar {
  background: #f56c6c;
  border-radius: 10px;
  &:hover {
    box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.2);
  }

  :deep(.el-calendar__body) {
    padding-bottom: 12px !important;
    .el-calendar-day {
      height: auto !important;
    }
    .el-calendar-table td {
      border: none;
      text-align: center;
    }
  }
}
</style>
