<template>
  <div
    v-resize="myChart && myChart.resize"
    style="height: 400px; margin-top: 20px; background: #fff"
    ref="pieChart"
  ></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import * as dayjs from "dayjs";
import { unwarp } from "@/utils";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const dataList = () => {
      let date = [];
      let num = [];
      let nowTime = new Date().getTime();
      for (let i = 0; i < 30; i++) {
        date.push(dayjs(nowTime - i * 1000).format("HH:mm:ss"));
        num.push((Math.random() * 10).toFixed(0) * 1);
      }
      return {
        date: date.reverse(),
        num,
      };
    };
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      PieChart,
      CanvasRenderer,
      LegendComponent,
    ]);
    const pieChart = ref(null);
    const myChart = ref(null);

    let option = {
      title: {
        text: "用户访问量",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "40",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 1048, name: "Search Engine" },
            { value: 735, name: "Direct" },
            { value: 580, name: "Email" },
            { value: 484, name: "Union Ads" },
            { value: 300, name: "Video Ads" },
          ],
        },
      ],
    };
    /**
     * @description: 动态数据
     * @param {*}
     * @return {*}
     */
    const getNewData = (myChart) => {
      unwarp(myChart.value).setOption(option);
    };
    onMounted(() => {
      myChart.value = echarts.init(pieChart.value);
      getNewData(myChart);
    });

    return {
      dataList,
      // $_dataList,
      pieChart,
      myChart,
      getNewData,
    };
  },
});
</script>

<style lang="scss" scoped></style>
